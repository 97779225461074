import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";

const List = () => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 50,
  });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const res = await api.post(`/company/search`, filters);
        if (res.ok) {
          setData(res.data);
          setTotal(res.total);
        } else toast.error("Une erreur est survenue");
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  return (
    <div className="p-8">
      <div className="flex justify-end items-center">
        <Link to="/company/new" className="primary-button">
          Ajouter une agence
        </Link>
      </div>

      <section className="w-full p-8 flex flex-col">
        <div className="flex justify-between items-center mb-4 px-4">
          <h3 className="flex-1 text-sm font-semibold">Nom</h3>
          <h3 className="flex-1 text-sm font-semibold">E-mail associé</h3>
          <h3 className="w-[15%] text-sm font-semibold">Date de création</h3>
        </div>

        <div className="space-y-2">
          {loading ? (
            <Loader />
          ) : (
            data.map((e, index) => (
              <div key={index} className="flex justify-between items-center gap-3">
                <Link to={`/company/${e._id}`} className="flex-1 border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center p-4">
                  <div className="flex-1">{e.name}</div>
                  <div className="flex-1">{e.doxallia_email}</div>
                  <div className="w-[15%]">{new Date(e.created_at).toLocaleDateString("fr-FR")}</div>
                </Link>
              </div>
            ))
          )}
        </div>
        <div className="w-full flex justify-center mt-8">
          <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
        </div>
      </section>
    </div>
  );
};

export default List;
