import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const View = ({ data }) => {
  if (!data) return <></>;

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="text-sm font-semibold">Nom</label>
          <div className="border border-secondary rounded-lg p-4">{data.name}</div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold">Typologie</label>
          <div className="border border-secondary rounded-lg p-4">{data.type}</div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold">Information de l'utilisateur</label>
          <div className="border border-secondary rounded-lg p-5 flex justify-between items-center">
            <div>
              <p className="text-primary text-sm font-medium">
                Prénom: <span className="text-sm text-black">{data.user_first_name}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Nom: <span className="text-sm text-black">{data.user_last_name}</span>
              </p>
            </div>
            <Link to={`/user/${data.user_id}`}>
              <FaExternalLinkSquareAlt className="w-6 h-6 text-primary" />
            </Link>
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold">Date de création</label>
          <div className="border border-secondary rounded-lg p-4">{new Date(data.created_at).toLocaleDateString("fr-FR")}</div>
        </div>
      </div>
    </div>
  );
};

export default View;
