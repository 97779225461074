import React from "react";
import { Route, Routes } from "react-router-dom";

import View from "./view";
import List from "./List";
import New from "./New";

const Company = () => (
  <Routes>
    <Route path="/" element={<List />} />
    <Route path="/new" element={<New />} />
    <Route path="/:id/*" element={<View />} />
  </Routes>
);

export default Company;
