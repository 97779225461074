import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import Select from "../../components/Select";

const New = () => {
  const [values, setValues] = useState({
    reference: "",
    address: "",
    company_id: "",
    company_name: "",
    price: "",
  });
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCompanies();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const res = await api.post("/property", values);
      if (res.ok) {
        toast.success("Location créée");
        navigate("/property");
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const getCompanies = async () => {
    const { data, ok } = await api.post("/company/search");
    if (!ok) return toast.error("Error!");
    setCompanies(data);
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold">Nouvelle location</h1>

      <div className="grid grid-cols-2 gap-4 mt-8">
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Référence de la location
          </label>
          <input type="text" id="name" className="input" value={values.reference} onChange={(e) => setValues({ ...values, reference: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Adresse de la location
          </label>
          <input type="text" id="name" className="input" value={values.address} onChange={(e) => setValues({ ...values, address: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Prix de la location
          </label>
          <input type="number" id="name" className="input" value={values.price} onChange={(e) => setValues({ ...values, price: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Agence de la location
          </label>
          <Select
            options={companies.map((company) => company._id)}
            value={values.company_id}
            onChange={(value) => setValues({ ...values, company_id: value, company_name: companies?.filter((company) => company._id === value)?.[0].name })}
            getLabel={(id) => companies?.filter((company) => company._id === id)?.[0].name}
          />
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button className="primary-button" onClick={handleSubmit}>
          {loading ? "Chargement..." : "Créer"}
        </button>
      </div>
    </div>
  );
};

export default New;
