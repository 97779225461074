import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";

const List = () => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 50,
  });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetcgData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", filters);
        if (res.ok) {
          console.log(res);
          setData(res.data);
          setTotal(res.total);
        }
      } catch (err) {
        console.error(err);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetcgData();
  }, [filters]);

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold">Documents</h1>
      <div className="flex justify-between items-center mt-8">{/* FILTERS */}</div>

      <section className="w-full p-8 flex flex-col">
        <div className="flex justify-between items-center px-4 mb-4">
          <h3 className="flex-1 text-sm font-semibold">Nom du document</h3>
          <h3 className="flex-1 text-sm font-semibold">Nom de l'expéditeur</h3>
          <h3 className="flex-1 text-sm font-semibold">type</h3>
          <h3 className="w-[15%] text-sm font-semibold">Date de création</h3>
        </div>
        <div className="space-y-2">
          {loading ? (
            <Loader />
          ) : (
            data.map((e, index) => (
              <Link to={`/document/${e._id}`} key={index} className="border border-secondary hover:border-black transition-all rounded-lg  flex justify-between items-center p-4">
                <div className="flex-1">{e.name}</div>
                <div className="flex-1">
                  {e.user_first_name} {e.user_last_name}
                </div>
                <div className="flex-1">{e.type ?? null}</div>
                <div className="w-[15%]">{new Date(e.created_at).toLocaleDateString("fr-FR")}</div>
              </Link>
            ))
          )}
        </div>
        <div className="w-full flex justify-center mt-8">
          <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
        </div>
      </section>
    </div>
  );
};

export default List;
