import React, { useState } from "react";
import { toast } from "react-hot-toast";

import useStore from "../../services/store";
import api from "../../services/api";

const Login = () => {
  const [seePassword, setSeePassword] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const { setUser } = useStore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await api.post(`/admin/signin`, values);
      if (res.ok) {
        api.setToken(res.data.token);
        setUser(res.data.user);
      }
    } catch (error) {
      toast.error("Une erreur est survenue");
    }
  };

  return (
    <div className="w-1/2">
      <h1 className="text-center text-3xl font-semibold text-black mb-12">Se connecter</h1>

      <form className="w-full space-y-4" onSubmit={handleSubmit}>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="email">
            Email
          </label>
          <input type="email" name="email" className="input" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
        </div>
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <label className="text-base font-medium" htmlFor="password">
              Mot de passe
            </label>
          </div>
          <div className="relative">
            <input
              type={seePassword ? "text" : "password"}
              name="password"
              className="input"
              value={values.password}
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              placeholder={"Enter your password here"}
            />
            <div className="absolute top-1/2 -translate-y-1/2 right-4">{seePassword ? <p>X</p> : <p>Y</p>}</div>
          </div>
        </div>
        <button disabled={!values.email || !values.password} type="submit" className="primary-button w-full !mt-10">
          Se connecter
        </button>
      </form>
    </div>
  );
};

export default Login;
