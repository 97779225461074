import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import api from "../../../services/api";
import Loader from "../../../components/Loader";
import Select from "../../../components/Select";

const CONTRACT_TITLE = {
  CDIE: "CDI en période d'essai",
  CDD: "CDD",
  CDI: "CDI sans période d'essai",
  OTHER: "Autre ou Chômage",
};

const CONTRACT_TYPES = ["CDIE", "CDD", "CDI", "OTHER"];

const DocumentsDetails = ({ report, onChange }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    identity_fraud: "",
    cni_statut: "",
    cni_birth_date: "",
    cni_name: "",
    cni_firstname: "",
    cni_age: 0,
    payslip_statut: "",
    payslip_contract_duration: 0,
    payslip_type: "",
    payslip_name: "",
    payslip_gross_salary: 0,
    payslip_net_salary: 0,
    payslip_sector: "",
    payslip_contract_type: "",
    payslip_company_name: "",
    payslip_naf_code: "",
    tax_notice_statut: "",
    tax_notice_type: "",
    tax_notice_name: "",
    tax_notice_birth_name: "",
    tax_notice_fiscal_revenue: 0,
    rent_receipt_statut: "",
    rent_receipt_type: "",
    rent_receipt_name: "",
    rent_receipt_amount: 0,
  });
  const [tenant, setTenant] = useState({
    identity_fraud: "",
    cni_type: "",
    cni_statut: "",
    cni_birth_date: "",
    cni_name: "",
    cni_firstname: "",
    cni_age: 0,
  });

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingScoring, setLoadingScoring] = useState(false);

  useEffect(() => {
    if (!report._id) return;
    fetchData();
  }, [report]);

  const fetchData = async () => {
    setLoading(true);
    if (!report.scoring_id || (report.has_guarantor && !report.guarantor_scoring_id)) return setLoading(false);
    try {
      if (report.has_guarantor) {
        const resG = await api.get(`/scoring/${report.guarantor_scoring_id}`);
        if (!resG.ok) throw resG;
        const resT = await api.get(`/scoring/${report.scoring_id}`);
        if (!resT.ok) throw resT;

        if (resG.data.cni_birth_date?.includes("/")) {
          const [day, month, year] = resG.data.cni_birth_date.split("/");
          resG.data.cni_birth_date = new Date(`${year}-${month}-${day}`);
        }
        if (resT.data.cni_birth_date?.includes("/")) {
          const [day, month, year] = resT.data.cni_birth_date.split("/");
          resT.data.cni_birth_date = new Date(`${year}-${month}-${day}`);
        }

        setData((prev) => ({ ...prev, ...resG.data }));
        setTenant((prev) => ({ ...prev, ...resT.data }));
      } else {
        const resT = await api.get(`/scoring/${report.scoring_id}`);
        if (!resT.ok) throw resT;
        if (resT.data.cni_birth_date?.includes("/")) {
          const [day, month, year] = date.split("/");
          resT.data.cni_birth_date = new Date(`${year}-${month}-${day}`);
        }
        setData((prev) => ({ ...prev, ...resT.data }));
      }
      const resD = await api.post("/document/search", { documents: report.document_ids });
      if (!resD.ok) throw resD;
      setDocuments(resD.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const handleSave = async () => {
    if (!report.scoring_id || (report.has_guarantor && !report.guarantor_scoring_id)) {
      setData({});
      setTenant({});
      return;
    }
    try {
      if (report.has_guarantor) {
        const resG = await api.put(`/scoring/${report.guarantor_scoring_id}`, data);
        if (!resG.ok) throw resG;
        const resT = await api.put(`/scoring/${report.scoring_id}`, tenant);
        if (!resT.ok) throw resT;
        setData((prev) => ({ ...prev, ...resG.data }));
        setTenant((prev) => ({ ...prev, ...resT.data }));
      } else {
        const resT = await api.put(`/scoring/${report.scoring_id}`, data);
        if (!resT.ok) throw resT;
        setData((prev) => ({ ...prev, ...resT.data }));
      }
      toast.success("Scoring mis à jour !");
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const handleGenerateScoring = async () => {
    setLoadingScoring(true);
    try {
      const res = await api.post(`/report/${report._id}/submit`);
      if (!res.ok) throw res;
      toast.success("Scoring en cours");
      onChange(res.data);
    } catch (error) {
      toast.error(error.message);
    }
    setLoadingScoring(false);
  };

  const handleCalculate = async () => {
    try {
      let res, coupleRes;
      if (report.has_guarantor) {
        res = await api.put(`/scoring/${report.guarantor_scoring_id}/calculate`, { report_id: report._id });
      } else {
        res = await api.put(`/scoring/${report.scoring_id}/calculate`, { report_id: report._id });
      }
      if (!res.ok) throw res;
      if (report.situation === "COUPLE") {
        const resC = await api.get(`/report/${report.couple_report_id}`);
        if (!resC.ok) throw resC;

        if (resC.data.has_guarantor) {
          coupleRes = await api.put(`/scoring/${resC.data.guarantor_scoring_id}/calculate`, { report_id: resC.data._id });
        } else {
          coupleRes = await api.put(`/scoring/${resC.data.scoring_id}/calculate`, { report_id: resC.data._id });
        }
        if (!coupleRes.ok) throw coupleRes;
        let overviewRes;
        if (report.is_spouse) {
          overviewRes = await api.put(`/report/${resC.data._id}`, {
            overview_global_score: (res.data.final_score + coupleRes.data.final_score) / 2,
            overview_global_rate_effort: (res.data.effort_rate + coupleRes.data.effort_rate) / 2,
            overview_global_rental_capacity: res.data.rental_capacity + coupleRes.data.rental_capacity,
            overview_global_income: res.data.payslip_gross_salary * 0.77 + coupleRes.data.payslip_gross_salary * 0.77,
            overview_global_tax_income: res.data.tax_notice_fiscal_revenue + coupleRes.data.tax_notice_fiscal_revenue,
          });
        } else {
          overviewRes = await api.put(`/report/${report._id}`, {
            overview_global_score: (res.data.final_score + coupleRes.data.final_score) / 2,
            overview_global_rate_effort: (res.data.effort_rate + coupleRes.data.effort_rate) / 2,
            overview_global_rental_capacity: res.data.rental_capacity + coupleRes.data.rental_capacity,
            overview_global_income: res.data.payslip_gross_salary * 0.77 + coupleRes.data.payslip_gross_salary * 0.77,
            overview_global_tax_income: res.data.tax_notice_fiscal_revenue + coupleRes.data.tax_notice_fiscal_revenue,
          });
        }
        if (!overviewRes.ok) throw overviewRes;
      }
      setData(res.data);
      toast.success("Scoring calculé !");
      navigate("../scoring");
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="space-y-8 pb-24">
      {!report.scoring_id && (
        <div className="flex flex-col justify-center items-center p-6 border border-blue-400 rounded bg-blue-50 text-blue-600">
          <h2 className="text-xl font-semibold">Aucun scoring n'a été généré pour ce dossier</h2>
          <button onClick={handleGenerateScoring} className="primary-button mt-4" disabled={loadingScoring}>
            {loadingScoring ? "Génération en cours" : "Générer l'analyse Syspertec"}
          </button>
        </div>
      )}
      {report.scoring_id !== undefined && data.status === "FAILURE" && (
        <div className="flex flex-col justify-center items-center p-6 border border-red-400 rounded bg-red-50 text-red-600">
          <h2 className="text-xl font-semibold">La verification des documents par Syspertec a échoué</h2>
          <button onClick={handleGenerateScoring} className="primary-button mt-4" disabled={loadingScoring}>
            {loadingScoring ? "Génération en cours" : "Régénerer l'analyse Syspertec"}
          </button>
        </div>
      )}
      <div className="space-y-4 py-8">
        <div className="space-y-2">
          <label className="text-md font-semibold">Informations du scoring de fraud</label>
          <div className="border border-secondary rounded-lg p-5 grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm font-semibold">
                {`score de fraud du ${report.has_guarantor ? "garant" : "locataire"}`}
              </label>
              <input type="text" id="name" className="input" value={data?.identity_fraud} onChange={(e) => setData({ ...data, identity_fraud: e.target.value })} />
            </div>
            {report.has_guarantor && (
              <div className="space-y-2">
                <label htmlFor="name" className="text-sm font-semibold">
                  score de fraud du locataire
                </label>
                <input type="text" id="name" className="input" value={tenant?.identity_fraud} onChange={(e) => setTenant({ ...tenant, identity_fraud: e.target.value })} />
              </div>
            )}
          </div>
        </div>
        {report.has_guarantor && (
          <>
            <h1 className="text-green-600 text-2xl font-semibold">Informations du locataire</h1>
            <Identity values={tenant} onChange={setTenant} documents={documents.filter((d) => d.type === "ID" && d.persona === "TENANT")[0]} />
          </>
        )}
        <h1 className="text-green-600 text-2xl font-semibold">{`Informations du ${report.has_guarantor ? "garant" : "locataire"}`}</h1>
        <Identity
          values={data}
          onChange={setData}
          documents={documents.filter((d) => (report.has_guarantor ? d.type === "ID" && d.persona === "GUARANTOR" : d.type === "ID" && d.persona === "TENANT"))[0]}
        />
        <Payslips
          values={data}
          onChange={setData}
          documents={documents.filter((d) => (report.has_guarantor ? d.type === "INCOME" && d.persona === "GUARANTOR" : d.type === "INCOME" && d.persona === "TENANT"))}
        />

        <Rent
          values={data}
          onChange={setData}
          documents={
            documents.filter((d) => (report.has_guarantor ? d.type === "RENT_RECEIPT" && d.persona === "GUARANTOR" : d.type === "RENT_RECEIPT" && d.persona === "TENANT"))[0]
          }
        />
        <TaxNotive
          values={data}
          onChange={setData}
          documents={documents.filter((d) => (report.has_guarantor ? d.type === "TAX_NOTICE" && d.persona === "GUARANTOR" : d.type === "TAX_NOTICE" && d.persona === "TENANT"))[0]}
        />
      </div>
      <div className="fixed bottom-4 right-8 flex items-center gap-4">
        <button onClick={handleCalculate} className="primary-button">
          Calculer le scoring
        </button>
        <button onClick={handleSave} className="primary-button">
          Enregistrer les modifications
        </button>
      </div>
    </div>
  );
};

const Identity = ({ values, onChange, documents }) => {
  const getAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const m = today.getMonth() - birth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <div className="space-y-2">
      <label className="text-xl font-semibold">Carte Nationale d'Identité</label>
      <div className="grid grid-cols-3">
        <div className="border border-secondary rounded-l-lg p-5 flex flex-col col-span-1 gap-4 h-[600px] overflow-y-auto">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Validité de la carte
            </label>
            <Select
              options={["00", "INCOHERENT"]}
              value={values?.cni_statut}
              onChange={(value) => onChange({ ...values, cni_statut: value })}
              getLabel={(type) => (type === "00" ? "Valide" : "Invalide")}
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Date de naissance
            </label>
            <input
              type="date"
              id="name"
              className="input"
              value={values?.cni_birth_date ? new Date(values?.cni_birth_date).toISOString().split("T")[0] : ""}
              onChange={(e) => {
                const age = getAge(e.target.value);
                onChange({ ...values, cni_birth_date: new Date(e.target.value), cni_age: age });
              }}
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Nom
            </label>
            <input type="text" id="name" className="input" value={values?.cni_name} onChange={(e) => onChange({ ...values, cni_name: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Prénom
            </label>
            <input type="text" id="name" className="input" value={values?.cni_firstname} onChange={(e) => onChange({ ...values, cni_firstname: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Age
            </label>
            <input type="number" id="name" className="input" value={values?.cni_age} onChange={(e) => onChange({ ...values, cni_age: e.target.value })} />
          </div>
        </div>
        <div className="col-span-2">
          <iframe src={documents?.url} width="100%" height="100%" title="pdf" />
        </div>
      </div>
    </div>
  );
};

const Payslips = ({ values, onChange, documents }) => {
  const [page, setPage] = useState(0);
  return (
    <div className="space-y-2">
      <div className="flex justify-start items-center gap-4">
        <label className="text-xl font-semibold">Fiche de Paie - Numéro {page + 1}</label>
        <button onClick={() => setPage(page - 1)} disabled={page === 0} className={`px-4 py-2 rounded-lg ${page === 0 ? "bg-gray-300 text-gray-500" : "bg-primary text-white"}`}>
          <RiArrowLeftSLine />
        </button>
        <button onClick={() => setPage(page + 1)} disabled={page > 2} className={`px-4 py-2 rounded-lg ${page === 2 ? "bg-gray-300 text-gray-500" : "bg-primary text-white"}`}>
          <RiArrowRightSLine />
        </button>
      </div>
      <div className="grid grid-cols-3">
        <div className="border border-secondary rounded-l-lg p-5 flex flex-col col-span-1 gap-4 h-[600px] overflow-y-auto">
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Validité de la fiche de paie
            </label>
            <Select
              options={["00", "INCOHERENT"]}
              value={values?.payslip_statut}
              onChange={(value) => onChange({ ...values, payslip_statut: value })}
              getLabel={(type) => (type === "00" ? "Valide" : "Invalide")}
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Salaire brut mensuel
            </label>
            <input type="number" id="name" className="input" value={values?.payslip_gross_salary} onChange={(e) => onChange({ ...values, payslip_gross_salary: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Salaire net mensuel
            </label>
            <input type="number" id="name" className="input" value={values?.payslip_net_salary} onChange={(e) => onChange({ ...values, payslip_net_salary: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Type de contrat
            </label>
            <Select
              options={CONTRACT_TYPES}
              value={values?.payslip_contract_type}
              onChange={(value) => onChange({ ...values, payslip_contract_type: value })}
              getLabel={(type) => CONTRACT_TITLE[type]}
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Ancienneté
            </label>
            <input
              type="text"
              id="name"
              className="input"
              value={values?.payslip_contract_duration}
              onChange={(e) => onChange({ ...values, payslip_contract_duration: e.target.value })}
            />
          </div>
        </div>

        <div className="col-span-2">
          <iframe src={documents[page]?.url} width="100%" height="100%" title="pdf" />
        </div>
      </div>
    </div>
  );
};

const TaxNotive = ({ values, onChange, documents }) => (
  <div className="space-y-2">
    <label className="text-md font-semibold">Informations de l'avis d'imposition</label>
    <div className="grid grid-cols-3">
      <div className="border border-secondary rounded-l-lg p-5 flex flex-col col-span-1 gap-4 h-[600px] overflow-y-auto">
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Validité de l'avis d'imposition
          </label>
          <Select
            options={["00", "INCOHERENT"]}
            value={values?.tax_notice_statut}
            onChange={(value) => onChange({ ...values, tax_notice_statut: value })}
            getLabel={(type) => (type === "00" ? "Valide" : "Invalide")}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Nom
          </label>
          <input type="text" id="name" className="input" value={values?.tax_notice_name} onChange={(e) => onChange({ ...values, tax_notice_name: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Nom de naissance
          </label>
          <input type="text" id="name" className="input" value={values?.tax_notice_birth_name} onChange={(e) => onChange({ ...values, tax_notice_birth_name: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Revenu fiscal de référence
          </label>
          <input
            type="number"
            id="name"
            className="input"
            value={(values?.tax_notice_fiscal_revenue || "").toString()}
            onChange={(e) => onChange({ ...values, tax_notice_fiscal_revenue: Number(e.target.value) })}
          />
        </div>
      </div>
      <div className="col-span-2">
        <iframe src={documents?.url} width="100%" height="100%" title="pdf" />
      </div>
    </div>
  </div>
);

const Rent = ({ values, onChange, documents }) => (
  <div className="space-y-2">
    <label className="text-md font-semibold">Informations de la quittance de loyer</label>
    <div className="grid grid-cols-3">
      <div className="border border-secondary rounded-l-lg p-5 flex flex-col col-span-1 gap-4 h-[600px] overflow-y-auto">
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Validité de la quittance de loyer
          </label>
          <Select
            options={["00", "INCOHERENT"]}
            value={values?.rent_receipt_statut}
            onChange={(value) => onChange({ ...values, rent_receipt_statut: value })}
            getLabel={(type) => (type === "00" ? "Valide" : "Invalide")}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Nom
          </label>
          <input type="text" id="name" className="input" value={values?.rent_receipt_name} onChange={(e) => onChange({ ...values, rent_receipt_name: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Montant
          </label>
          <input type="number" id="name" className="input" value={values?.rent_receipt_amount} onChange={(e) => onChange({ ...values, rent_receipt_amount: e.target.value })} />
        </div>
      </div>
      <div className="col-span-2">
        <iframe src={documents?.url} width="100%" height="100%" title="pdf" />
      </div>
    </div>
  </div>
);

const isValidDate = (date) => {
  try {
    new Date(date).toISOString();
    return true;
  } catch (error) {
    return false;
  }
};

export default DocumentsDetails;
