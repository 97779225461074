import React from "react";

const Loader = ({ color = "border-l-primary", size = "w-16 h-16 border-[6px]" }) => {
  return (
    <div className="flex items-center justify-center space-x-2 m-20">
      <div className={`${color} animate-spin inline-block ${size} rounded-full bg-transparent`} role="status" />
    </div>
  );
};

export default Loader;
