import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";

import View from "./View";
import Documents from "./Document";
import api from "../../../services/api";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";

const Index = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const tab = location.pathname.split(id).pop();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/email/${id}`);
        if (res.ok) {
          setData(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  if (loading) return <Loader />;

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-8">
        Email de {data.from_email} à {data.to?.map((e) => e.email).join(",")} le {new Date(data.sent_at).toLocaleDateString("fr-FR")}
      </h1>
      <div className="flex gap-4">
        <Link to="" className={`rounded-lg px-4 py-2 ${tab === "" ? "bg-gray-100" : "border border-secondary"}`}>
          Infos
        </Link>
        <Link to="document" className={`rounded-lg px-4 py-2 ${tab === "/document" ? "bg-gray-100" : "border border-secondary"}`}>
          Pièces jointes
        </Link>
        <Link to="raw" className={`rounded-lg px-4 py-2 ${tab === "/raw" ? "bg-gray-100" : "border border-secondary"}`}>
          Données brutes
        </Link>
      </div>
      <section className="mt-8">
        <Routes>
          <Route path="/" element={<View data={data} />} />
          <Route path="/document" element={<Documents data={data} />} />
          <Route path="/raw" element={<Raw data={data} />} />
        </Routes>
      </section>
    </div>
  );
};

const Raw = ({ data }) => (
  <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
    <pre className="text-white text-sm">{JSON.stringify(data, null, 2)}</pre>
  </div>
);

export default Index;
