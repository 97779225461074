import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../../services/api";
import Select from "../../../components/Select";

const Info = ({ data, setData }) => {
  const [values, setValues] = useState({
    reference: "",
    address: "",
    company_id: "",
    company_name: "",
    manager: "",
    price: "",
    status: "",
  });
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) return;
    setValues({
      reference: data.reference,
      address: data.address,
      company_id: data.company_id,
      company_name: data.company_name,
      price: data.price,
      status: data.status,
      manager: data.manager,
    });
  }, [data]);

  useEffect(() => {
    getCompanies();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    try {
      const res = await api.put(`/property/${data._id}`, { ...values });
      if (res.ok) {
        toast.success("Location modifiée");
        setData(res.data);
      } else {
        toast.error("Une erreur est survenue");
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const getCompanies = async () => {
    const { data, ok } = await api.post("/company/search");
    if (!ok) return toast.error("Error!");
    setCompanies(data);
  };

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-4 mt-8">
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Référence
          </label>
          <input type="text" id="name" className="input" value={values.reference} onChange={(e) => setValues({ ...values, reference: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Adresse
          </label>
          <input type="text" id="name" className="input" value={values.address} onChange={(e) => setValues({ ...values, address: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Prix
          </label>
          <input type="number" id="name" className="input" value={values.price} onChange={(e) => setValues({ ...values, price: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Agence
          </label>
          <Select
            options={companies.map((company) => company._id)}
            value={values.company_id}
            onChange={(value) => setValues({ ...values, company_id: value, company_name: companies?.filter((company) => company._id === value)?.[0]?.name })}
            getLabel={(id) => companies?.filter((company) => company._id === id)?.[0]?.name}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Responsable
          </label>
          <input type="text" id="name" className="input" value={values.manager} onChange={(e) => setValues({ ...values, manager: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Statut
          </label>
          <Select
            options={["AVAILABLE", "PENDING", "RENTED"]}
            value={values.status}
            onChange={(value) => setValues({ ...values, status: value })}
            getLabel={(status) => <Status status={status} />}
          />
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button className="primary-button" onClick={handleSave}>
          {loading ? "Chargement..." : "Enregistrer"}
        </button>
      </div>
    </div>
  );
};

const Status = ({ status }) => {
  let bgColor;
  let textColor;
  if (status === "AVAILABLE") {
    bgColor = "bg-green-100";
    textColor = "text-green-600";
  } else if (status === "PENDING") {
    bgColor = "bg-orange-100";
    textColor = "text-orange-600";
  } else {
    bgColor = "bg-violet-100";
    textColor = "text-violet-600";
  }
  const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  return <span className={`inline-flex items-center gap-x-1.5 rounded-md ${bgColor} px-2 py-1 text-xs font-medium ${textColor}`}>{formattedStatus}</span>;
};
export default Info;
