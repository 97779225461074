import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../../services/api";
import Loader from "../../../components/Loader";
import { MdOutlineFileDownload } from "react-icons/md";
import Select from "../../../components/Select";

const DOCUMENT_TITLE = {
  ID: "Document d'identité",
  RENT_RECEIPT: "Justificatif d'hébergement",
  TAX_NOTICE: "Avis d'imposition",
  INCOME: "Bulletin de salaire",
  OTHER: "Autre",
};

const DOCUMENT_TYPES = ["OTHER", "ID", "RENT_RECEIPT", "TAX_NOTICE", "INCOME"];

const Documents = ({ report, onChange }) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!report) return;
    fetchData();
  }, [report]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.post("/document/search", { documents: report.document_ids });
      if (res.ok) {
        setData(res.data);
        setTotal(res.total);
      }
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const onUpdate = async (document) => {
    try {
      const res = await api.put(`/document/${document._id}`, document);
      if (!res.ok) throw res;
      const updatedDocuments = data.map((doc) => (doc._id === document._id ? document : doc));
      setData(updatedDocuments);
      onChange();
    } catch (error) {
      toast.error("errewfwtor");
    }
  };

  const getColorConfidence = (confidence) => {
    if (confidence < 0.3) {
      return "text-red-500";
    } else if (confidence >= 0.3 && confidence <= 0.6) {
      return "text-orange-500";
    } else {
      return "text-green-500";
    }
  };

  if (report.has_guarantor) {
    return (
      <>
        <h3 className="text-xl font-bold">{data.filter((d) => d.persona === "TENANT").length} Documents associés au locataire</h3>
        <div className="w-full p-8 flex flex-col">
          <div className="flex justify-between items-center px-4 mb-4">
            <h3 className="flex-1 text-sm font-semibold">Nom</h3>
            <h3 className="w-[25%] mr-5 text-sm font-semibold">Typologie</h3>
            <h3 className="w-[15%] text-sm font-semibold text-center">Model utilisé</h3>
            <h3 className="w-[15%] text-sm font-semibold text-center">Confiance</h3>
            <h3 className="w-[15%] text-sm font-semibold text-center">Télécharger</h3>
          </div>
          <div className="space-y-2">
            {loading ? (
              <Loader />
            ) : (
              data
                .filter((d) => d.persona === "TENANT")
                .map((e, index) => (
                  <div key={index} className="border border-secondary  rounded-lg flex justify-between items-center p-4">
                    <div className="flex-1 truncate">{e.name}</div>
                    <div className="mr-5 w-[25%]">
                      <Select options={DOCUMENT_TYPES} value={e?.type} onChange={(value) => onUpdate({ ...e, type: value })} getLabel={(type) => DOCUMENT_TITLE[type]} />
                    </div>
                    <div className="w-[15%] text-center">{e.azure_raw?.modelId}</div>
                    <div className={`w-[15%] text-center ${getColorConfidence(e.azure_raw?.documents[0].confidence)}`}>{e.azure_raw?.documents[0].confidence}</div>
                    <div className="w-[15%] flex justify-center">
                      <a href={e.url} target="_blank" rel="noreferrer" className="text-primary text-center">
                        <MdOutlineFileDownload />
                      </a>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
        <h3 className="text-xl font-bold">{data.filter((d) => d.persona === "GUARANTOR").length} Documents associés au garant</h3>
        <div className="w-full p-8 flex flex-col">
          <div className="flex justify-between items-center px-4 mb-4">
            <h3 className="flex-1 text-sm font-semibold">Nom</h3>
            <h3 className="w-[25%] mr-5 text-sm font-semibold">Typologie</h3>
            <h3 className="w-[15%] text-sm font-semibold text-center">Model utilisé</h3>
            <h3 className="w-[15%] text-sm font-semibold text-center">Confiance</h3>
            <h3 className="w-[15%] text-sm font-semibold text-center">Télécharger</h3>
          </div>
          <div className="space-y-2">
            {loading ? (
              <Loader />
            ) : (
              data
                .filter((d) => d.persona === "GUARANTOR")
                .map((e, index) => (
                  <div key={index} className="border border-secondary  rounded-lg flex justify-between items-center p-4">
                    <div className="flex-1 truncate">{e.name}</div>
                    <div className="mr-5 w-[25%]">
                      <Select options={DOCUMENT_TYPES} value={e?.type} onChange={(value) => onUpdate({ ...e, type: value })} getLabel={(type) => DOCUMENT_TITLE[type]} />
                    </div>
                    <div className="w-[15%] text-center">{e.azure_raw?.modelId}</div>
                    <div className={`w-[15%] text-center ${getColorConfidence(e.azure_raw?.documents[0].confidence)}`}>{e.azure_raw?.documents[0].confidence}</div>
                    <div className="w-[15%] flex justify-center">
                      <a href={e.url} target="_blank" rel="noreferrer" className="text-primary text-center">
                        <MdOutlineFileDownload />
                      </a>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <h3 className="text-xl font-bold">{total} Documents associés</h3>
      <div className="w-full p-8 flex flex-col">
        <div className="flex justify-between items-center px-4 mb-4">
          <h3 className="flex-1 text-sm font-semibold">Nom</h3>
          <h3 className="w-[25%] mr-5 text-sm font-semibold">Typologie</h3>
          <h3 className="w-[15%] text-sm font-semibold text-center">Model utilisé</h3>
          <h3 className="w-[15%] text-sm font-semibold text-center">Confiance</h3>
          <h3 className="w-[15%] text-sm font-semibold text-center">Télécharger</h3>
        </div>
        <div className="space-y-2">
          {loading ? (
            <Loader />
          ) : (
            data.map((e, index) => (
              <div key={index} className="border border-secondary  rounded-lg flex justify-between items-center p-4">
                <div className="flex-1 truncate">{e.name}</div>
                <div className="mr-5 w-[25%]">
                  <Select options={DOCUMENT_TYPES} value={e?.type} onChange={(value) => onUpdate({ ...e, type: value })} getLabel={(type) => DOCUMENT_TITLE[type]} />
                </div>
                <div className="w-[15%] text-center">{e.azure_raw?.modelId}</div>
                <div className={`w-[15%] text-center ${getColorConfidence(e.azure_raw?.documents[0].confidence)}`}>{e.azure_raw?.documents[0].confidence}</div>
                <div className="w-[15%] flex justify-center">
                  <a href={e.url} target="_blank" rel="noreferrer" className="text-primary text-center">
                    <MdOutlineFileDownload />
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Documents;
