import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../../services/api";
import Loader from "../../../components/Loader";
import { MdOutlineFileDownload } from "react-icons/md";

const Documents = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetcgData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/document/search", { email_id: id });
        if (res.ok) {
          setData(res.data);
          setTotal(res.total);
        }
      } catch (err) {
        console.error(err);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetcgData();
  }, []);

  return (
    <>
      <h3 className="text-xl font-bold">{total} Pièces jointes</h3>
      <div className="w-full p-8 flex flex-col">
        <div className="flex justify-between items-center px-4 mb-4">
          <h3 className="flex-1 text-sm font-semibold">Nom</h3>
          <h3 className="w-[15%] text-sm font-semibold text-center">Type</h3>
          <h3 className="w-[15%] text-sm font-semibold text-center">Taille</h3>
          <h3 className="w-[15%] text-sm font-semibold text-center">Télécharger</h3>
        </div>
        <div className="space-y-2">
          {loading ? (
            <Loader />
          ) : (
            data.map((e, index) => (
              <div key={index} className="border border-secondary hover:border-black transition-all rounded-lg flex justify-between items-center p-4">
                <div className="flex-1 truncate">{e.name}</div>
                <div className="w-[15%] text-center">{e.content_type}</div>
                <div className="w-[15%] text-center">{e.content_length}</div>
                <div className="w-[15%] flex justify-center">
                  <a href={e.url} target="_blank" rel="noreferrer" className="text-primary text-center">
                    <MdOutlineFileDownload />
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Documents;
