import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../services/api";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";

const View = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/admin/user/${id}`);
        if (res.ok) {
          setData(res.data);
        }
      } catch (error) {
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold">
        {data.first_name} {data.last_name}
      </h1>

      <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
        <pre className="text-white text-sm">{JSON.stringify(data, null, 2)}</pre>
      </div>
    </div>
  );
};

export default View;
