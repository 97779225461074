import { useState } from "react";
import toast from "react-hot-toast";

import api from "../../services/api";
import { useNavigate } from "react-router-dom";

const New = () => {
  const [values, setValues] = useState({
    name: "",
    doxallia_email_split: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const res = await api.post("/company", { ...values, doxallia_email: `${values.doxallia_email_split}@doxallia-mail.fr`, doxallia_email_split: undefined });
      if (res.ok) {
        toast.success("Agence créée");
        // navigate("/company");
      } else {
        toast.error("Une erreur est survenue");
      }
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold">Nouvelle agence</h1>

      <div className="grid grid-cols-2 gap-4 mt-8">
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Nom
          </label>
          <input type="text" id="name" className="input" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Email de contact
          </label>
          <input type="email" id="email" className="input" value={values.email} onChange={(e) => setValues({ ...values, email: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="password" className="text-sm font-semibold">
            Email de récéption de dossier
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              id="password"
              className="input flex-1"
              value={values.doxallia_email_split}
              onChange={(e) => setValues({ ...values, doxallia_email_split: e.target.value })}
            />
            <p className="flex-1 text-xs text-gray-500 cursor-pointer">@doxallia-mail.fr</p>
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button className="primary-button" onClick={handleSubmit}>
          {loading ? "Chargement..." : "Créer"}
        </button>
      </div>
    </div>
  );
};

export default New;
