import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseLine, RiCheckLine } from "react-icons/ri";

const Select = ({
  options,
  value,
  onChange,
  disabled = false,
  multiple = false,
  nullable = false,
  placeholder = "Sélectionner une option",
  width = "w-full",
  position = "left-0 top-full",
  listHeader,
  getValue = (o) => o,
  getLabel = (o) => o,
  by = (a, b) => getValue(a) === getValue(b),
}) => (
  <Listbox value={value || ""} onChange={onChange} disabled={disabled} by={by} multiple={multiple}>
    {({ open }) => (
      <>
        <div className="relative w-full">
          <div className={`w-full flex items-center justify-between bg-white border border-secondary rounded-md hover:border-primary`}>
            <Listbox.Button
              className="flex-1 text-left placeholder:text-gray-600 text-sm py-3 px-4"
              onClick={(e) => {
                e.stopPropagation();
              }}>
              {multiple && value.length > 0 ? (
                <p className="truncate text-left text-sm text-black">
                  {getLabel(value[0])} {value.length > 1 && `+${value.length - 1}`}
                </p>
              ) : !multiple && value ? (
                <p className="truncate text-left text-sm text-black">{getLabel(value)}</p>
              ) : (
                <p className="truncate text-gray-700 opacity-60 text-left text-sm capitalize">{placeholder}</p>
              )}
              {((multiple && !value.length) || (!multiple && !value) || !nullable) && !disabled && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2">{open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
              )}
            </Listbox.Button>{" "}
            {((multiple && value.length > 0) || (!multiple && value)) && !disabled && nullable && (
              <button type="button" className="flex items-center pr-2" onClick={() => onChange(multiple ? [] : null)}>
                <RiCloseLine />
              </button>
            )}
          </div>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={`${width} ${position} absolute !m-0 !p-0 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-30`}>
              {listHeader}
              {options.map((option, i) => (
                <Listbox.Option key={i} value={option} className="relative w-full cursor-pointer select-none list-none" onClick={(e) => e.stopPropagation()}>
                  {({ active, selected }) => (
                    <div className={`${active ? "bg-gray-100" : "bg-transparent"} w-full !m-0 px-4 py-2 flex items-center justify-between gap-2`}>
                      <p className={`block truncate text-sm ${selected ? "font-semibold" : "font-normal"}`}>{getLabel(option)}</p>
                      {selected && <RiCheckLine />}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);

export default Select;
