import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Loader from "./Loader";

const Table = ({ header, sort, total, onSort, loading, children, fixed = false, sticky = false, height = "h-full" }) => {
  if (total === 0 && !loading) return <div className="w-full">No data found</div>;
  return (
    <div className={`w-full border rounded overflow-x-auto ${height}`}>
      <table className={`w-full ${fixed ? "table-fixed" : ""}`}>
        <thead className={`text-left ${sticky ? "sticky top-0 z-10 bg-white shadow-md" : ""}`}>
          <tr className="border-b">
            {header.map((item, index) => (
              <th
                key={index}
                className={`p-2 ${item.key && "hover:bg-neutral-50 cursor-pointer"}`}
                colSpan={item.colSpan || 1}
                onClick={() => {
                  item.key && onSort(item.key);
                }}>
                <div
                  className={`flex items-center ${
                    item.position === "right" ? "justify-end" : item.position === "center" ? "justify-center" : item.position === "between" ? "justify-between" : "justify-start"
                  }`}>
                  <h3 className="text-sm font-semibold">{item.title}</h3>
                  {item.key && (
                    <button className="flex flex-col justify-center">
                      {sort[item.key] === 1 ? <IoIosArrowUp /> : sort[item.key] === -1 ? <IoIosArrowDown /> : <IoIosArrowDown className=" opacity-0" />}
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="relative">{total === 0 && !loading ? <div className="w-full">No data found</div> : loading ? <Loader /> : children}</tbody>
      </table>
    </div>
  );
};

export default Table;
