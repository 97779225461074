import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import MultiSelect from "../../components/MultiSelect";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import { RxCross2 } from "react-icons/rx";

const HEADER = [
  { title: "Statut" },
  { title: "Email", key: "user_email", position: "between" },
  { title: "Ref. propriété", key: "property_reference", position: "between" },
  { title: "Situation", key: "situation", property: "w-20", position: "between" },
  { title: "Garant", key: "has_guarantor", property: "w-20", position: "between" },
  { title: "Date de dépot", key: "created_at", position: "between" },
];

const List = () => {
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 20,
    companies: [],
    sort: { created_at: -1 },
  });
  const [companies, setCompanies] = useState([]);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingRow, setLoadingRow] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    if (data.length === 0) setLoading(true);
    else setLoadingRow(true);
    try {
      const res = await api.post("/report/search", {
        skip: (filters.page - 1) * filters.pageSize,
        limit: filters.pageSize,
        // companies: filters.companies,
        sort: filters.sort,
      });
      if (res.ok) {
        setData(res.data);
        setTotal(res.total);
      }
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
    setLoadingRow(false);
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.post("/company/search");
        if (!res.ok) throw res;
        setCompanies(data);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
    };
    fetchData();
  }, []);

  const handleSort = (key) => {
    setFilters({ ...filters, sort: { [key]: !filters.sort[key] ? 1 : filters.sort[key] === 1 ? -1 : undefined } });
  };

  return (
    <div className="p-8 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Dossiers</h1>
        <div className="flex items-center gap-4">
          <button className="primary-button" onClick={() => setOpen(true)}>
            Créer un nouveau dossier
          </button>
          <MultiSelect
            values={filters.companies?.map((companyId) => ({ value: companyId }))}
            onSelectedChange={(e) => {
              const ids = e.map((e) => e.value);
              setFilters({ ...filters, companies: ids });
            }}
            placeholder="Companies"
            options={companies.map((f) => ({ value: f._id, label: f.name }))}
            renderOption={(item) => <div>{item}</div>}
          />
        </div>
      </div>

      <Table
        header={HEADER}
        sort={filters.sort}
        total={total}
        onSort={handleSort}
        loading={loading}
        height="h-[35rem]"
        sticky={true}
        noResultRender={<div className="w-full bg-white text-center p-2 my-5 rounded shadow-sm">Aucune candidature n'a été trouvé</div>}>
        {loadingRow
          ? [...Array(filters.pageSize)].map((_, index) => (
              <tr key={index} className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"}`}>
                {HEADER.map((_, index) => (
                  <td className="py-4 px-3">
                    <div className="h-4 bg-gray-200 rounded w-full" />
                  </td>
                ))}
              </tr>
            ))
          : data
              .filter((e) => !e.is_spouse)
              .map((item, index) => (
                <tr
                  key={index}
                  className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"} hover:bg-secondary`}
                  onClick={() => navigate(`/report/${item._id}`)}>
                  <td className="py-4 px-3 text-left">{STATUS_SPAN[item.status]}</td>
                  <td className="py-4 px-3 text-left">{item.user_email}</td>
                  <td className="py-4 px-3 text-left">{item.property_reference ?? "-"}</td>
                  <td className="py-4 px-3 text-right">{SITUATION_SPAN[item.situation]}</td>
                  <td className="py-4 px-3 text-left">{item.has_guarantor && SITUATION_SPAN["GUARANTOR"]}</td>
                  <DateTableCell d={item.created_at} />
                </tr>
              ))}
      </Table>
      <div className="w-full flex justify-center mt-8">
        <Pagination page={filters.page} setPage={(page) => setFilters({ ...filters, page })} last={Math.ceil(total / filters.pageSize)} />
      </div>
      {open && <ModalCreateReport show={open} onClose={() => setOpen(false)} />}
    </div>
  );
};

const DateTableCell = ({ d }) => {
  const date = new Date(d);
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("fr-FR", options);

  return <td className="py-4 px-3 text-right">{formattedDate}</td>;
};

const ModalCreateReport = ({ show, onClose }) => {
  const [values, setValues] = useState({
    user_email: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const { ok, data } = await api.post("/admin/report/", values);
      if (!ok) toast.error("Une erreur est survenue");
      toast.success("Dossier créée");
      onClose();
      navigate(`/report/${data._id}`);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div>
        <div className="w-full flex justify-between">
          <h3 className="text-2xl font-bold">Nouveau dossier</h3>
          <button onClick={closeModal} className="transparent-button">
            <RxCross2 />
          </button>
        </div>
        <div className="space-y-2">
          <label htmlFor="address" className="text-sm font-semibold">
            Email de l'utilisateur
          </label>
          <input type="text" id="name" className="input" value={values.user_email} onChange={(e) => setValues({ ...values, user_email: e.target.value })} />
        </div>

        <div className="mt-8 flex justify-end">
          <button className="primary-button" onClick={handleSubmit}>
            {loading ? "Chargement..." : "Créer le nouveau dossier"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const STATUS_SPAN = {
  INCOMPLETED: (
    <span className="inline-flex items-center gap-1.5  rounded-md bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600 border border-orange-600">
      <span className={`h-2 w-2 rounded-full bg-orange-400`} /> Incomplet
    </span>
  ),
  COMPLETED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <span className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
  PROCESSING: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-purple-100 px-3 py-1 text-xs font-medium text-purple-600 border border-purple-600">
      <span className={`h-2 w-2 rounded-full bg-purple-400`} />
      En traitement
    </span>
  ),
  VERIFIED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-green-100 px-3 py-1 text-xs font-medium text-green-600 border border-green-600">
      <span className={`h-2 w-2 rounded-full bg-green-400`} />
      Traité
    </span>
  ),
  FINALIZED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-blue-100 px-3 py-1 text-xs font-medium text-blue-600 border border-blue-600">
      <span className={`h-2 w-2 rounded-full bg-blue-400`} />
      Finalisé
    </span>
  ),
  SENT_TO_AGENCY: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-pink-100 px-3 py-1 text-xs font-medium text-pink-600 border border-pink-600">
      <span className={`h-2 w-2 rounded-full bg-pink-400`} />
      Envoyé à l'agence
    </span>
  ),
};
const SITUATION_SPAN = {
  COUPLE: <span className="inline-flex items-center gap-1.5  rounded-md bg-white px-3 py-1 text-xs font-medium text-pink-300 border border-pink-300">Couple</span>,
  SINGLE: <span className="inline-flex items-center gap-1.5 rounded-md bg-white px-3 py-1 text-xs font-medium text-primary border border-primary">Seul.e</span>,
  GUARANTOR: <span className="inline-flex items-center gap-1.5 rounded-md bg-white px-3 py-1 text-xs font-medium text-lime-300 border border-lime-300">Garant</span>,
};

export default List;
