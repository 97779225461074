import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const MENU = [
  { title: "Accueil", to: "/home" },
  { title: "Emails", to: "/email" },
  { title: "Utilisateurs", to: "/user" },
  { title: "Documents", to: "/document" },
  { title: "Dossiers", to: "/report" },
  { title: "Agences", to: "/company" },
  { title: "Locations", to: "/property" },
];

const Navbar = () => {
  const [selected, setSelected] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const index = MENU.findIndex((e) => location.pathname.includes(e.to));
    setSelected(index);
  }, [location]);

  return (
    <div className="h-screen bg-primary">
      <div className="h-full flex flex-col gap-5 items-center justify-between p-5">
        <h1 className="text-white text-2xl font-bold mb-12">Admin</h1>
        <ul className="list-none w-full flex-1 flex flex-col gap-2">
          {MENU.map((menu, index) => (
            <Link
              to={menu.to}
              key={menu.title}
              className={`w-full px-4 py-2 rounded text-base text-white ${selected === index ? "bg-white/10" : "hover:bg-secondary hover:text-black"}`}
              onClick={() => setSelected(index)}>
              <li className="w-full">{menu.title}</li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
