import { useEffect } from "react";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const View = ({ data }) => {
  if (!data) return <></>;

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="text-sm font-semibold">Référence de la location</label>
          <div className="border border-secondary rounded-lg p-4 flex justify-between">
            {data.property_reference}

            {data.property_reference && (
              <Link to={`/property/${data.property_id}`}>
                <FaExternalLinkSquareAlt className="w-6 h-6 text-primary" />
              </Link>
            )}
          </div>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-semibold">Date de création</label>
          <div className="border border-secondary rounded-lg p-4">{new Date(data.created_at).toLocaleDateString("fr-FR")}</div>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-semibold">Information de l'utilisateur</label>
          <div className="border border-secondary rounded-lg p-5 flex justify-between items-center">
            <div>
              <p className="text-primary text-sm font-medium">
                Nom et prénom :{" "}
                <span className="text-sm text-black">
                  {data.user_last_name} {data.user_first_name}
                </span>
              </p>
              <p className="text-primary text-sm font-medium">
                Email: <span className="text-sm text-black">{data.user_email}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Téléphone: <span className="text-sm text-black">{data.user_phone}</span>
              </p>
            </div>
            <Link to={`/user/${data.user_id}`}>
              <FaExternalLinkSquareAlt className="w-6 h-6 text-primary" />
            </Link>
          </div>
        </div>

        <div className="space-y-2">
          <label className="text-sm font-semibold">Information de l'agence</label>
          <div className="border border-secondary rounded-lg p-5 flex justify-between items-center">
            <div>
              <p className="text-primary text-sm font-medium">
                Nom: <span className="text-sm text-black">{data.company_name}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Email: <span className="text-sm text-black">{data.company_email}</span>
              </p>
              <p className="text-primary text-sm font-medium">
                Email de contact: <span className="text-sm text-black">{data.company_doxallia_email}</span>
              </p>
            </div>
            <Link to={`/company/${data.company_id}`}>
              <FaExternalLinkSquareAlt className="w-6 h-6 text-primary" />
            </Link>
          </div>
        </div>
        {data.has_guarantor && (
          <div className="space-y-2">
            <label className="text-sm font-semibold">Information du garant</label>
            <div className="border border-secondary rounded-lg p-5 flex justify-between items-center">
              <div>
                <p className="text-primary text-sm font-medium">
                  Nom et prénom :{" "}
                  <span className="text-sm text-black">
                    {data.guarantor_last_name} {data.guarantor_first_name}
                  </span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Email: <span className="text-sm text-black">{data.guarantor_email}</span>
                </p>
                <p className="text-primary text-sm font-medium">
                  Téléphone: <span className="text-sm text-black">{data.guarantor_phone}</span>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
