const View = ({ data }) => {
  if (!data) return <></>;

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="space-y-2">
        <label className="text-sm font-semibold">Expéditeur</label>
        <div className="border border-secondary rounded-lg p-4">{data.from_email}</div>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-semibold">Destinataire(s)</label>
        <div className="border border-secondary rounded-lg p-4">{(data.to || []).map((e) => e.email).join(", ")}</div>
      </div>

      <div className="space-y-2 col-span-2">
        <label className="text-sm font-semibold">Objet</label>
        <div className="border border-secondary rounded-lg p-4">{data.subject}</div>
      </div>
      <div className="space-y-2 col-span-2">
        <label className="text-sm font-semibold">Texte brut</label>
        <div className="border border-secondary rounded-lg p-4">{data.raw_text_body}</div>
      </div>
      <div className="space-y-2 col-span-2">
        <label className="text-sm font-semibold">Texte html</label>
        <div className="border border-secondary rounded-lg p-4">{data.raw_html_body}</div>
      </div>
      <div className="space-y-2 col-span-2">
        <label className="text-sm font-semibold">Texte markdown</label>
        <div className="border border-secondary rounded-lg p-4">{data.md_text_body}</div>
      </div>
    </div>
  );
};

export default View;
