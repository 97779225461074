import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../../services/api";
import Loader from "../../../components/Loader";
import Info from "./Info";

const Index = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const tab = location.pathname.split(id).pop();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/property/${id}`);
        if (res.ok) {
          setData(res.data);
        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  if (loading) return <Loader />;

  return (
    <div className="p-8">
      <div className="flex gap-4">
        <Link to="" className={`rounded-lg px-4 py-2 ${tab === "" ? "bg-gray-100" : "border border-secondary"}`}>
          Infos
        </Link>
        <Link to="raw" className={`rounded-lg px-4 py-2 ${tab === "/raw" ? "bg-gray-100" : "border border-secondary"}`}>
          Données brutes
        </Link>
      </div>
      <section className="mt-8">
        <Routes>
          <Route path="" element={<Info data={data} setData={setData} />} />
          <Route path="/raw" element={<Raw data={data} />} />
        </Routes>
      </section>
    </div>
  );
};

const Raw = ({ data }) => (
  <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
    <pre className="text-white text-sm">{JSON.stringify(data, null, 2)}</pre>
  </div>
);

export default Index;
